<template>
  <div v-if="item != null && binds != null" >
    <div class="smart-wrap quiestionaries-wrap">
      <h2 class="col-md-6" style="font-weight: 600; color: #4a4a4a;">{{recordView.layoutName}}</h2>
      <div class="col-md-6 absoluteCenterFlex button-q" style="margin:0; padding:0;">
        <ui-button v-if="$router.currentRoute.value.path != '/questionnaires'" :color="'gray'" :text="'Back'" :position="'right'" data-dismiss="modal" style="margin: 0 0 0 10px;" v-on:click="actionRecord('back')" />
        <ui-button v-if="$router.currentRoute.value.path != '/questionnaires'"  style="margin: 0 0 0 10px;" :color="'green'" :text="'Save and Back'" :position="'right'" :id="'saveClose-'+item.SK" v-on:click="actionRecord('saveClose')"/>
        <ui-button  style="margin: 0 0 0 10px;" :color="'green'" :text="'Save'" :position="'right'" :id="'save-'+item.SK" v-on:click="actionRecord('save')"/>
        <ui-button  style="margin: 0 0 0 10px;" :color="'green'" :text="'Send for Review'" :position="'right'" :id="'save-'+item.SK" v-on:click="actionRecord('save');sendReview(record); "/>
      </div>
    </div>
    <div class="body-wrap">
      <paper-layout style="" :filterSmarForm="filterSmarForm" :data="{PK:record.PK,SK:record.SK}" :layout="layout" :models="binds.attributes" :paperPermissions="permissions"/>
    </div>

  </div>
</template>
<script>
//Tools
import Vuex from 'vuex'
import { API, Storage } from 'aws-amplify'
import Swal from 'sweetalert2'
//AWS - GRAPHQL
import { smd_listPAGWithData, smd_getSMLItem, smd_composeEmailTemplate, smd_getRootDataSet,smd_getDashData  } from '../../graphql/queries'
import { smd_updateSmartRecord, smd_setItemState, smd_sendEmail2, smd_addToFavorites, smd_cloneSmartRecord,smd_notify } from '../../graphql/mutations'
import { onUpdateItem, onActivateRecord  } from '../../graphql/subscriptions'
//Components

import PaperLayout from './PaperLayout.vue'
import PaperPreview from './PaperPreview.vue'
import PaperDisplayer from './PaperDisplayer.vue'
import UiButton from '../ui/UiButton.vue'

import PaperLayput from './PaperLayout.vue'
  export default {
    name: "paper-record",
    components:{
      PaperLayout,
      PaperDisplayer,
      PaperPreview,
      UiButton,
    },
    data() {
      return {
        binds: null,
        layout: null,
        item: null,
        ACLPermits:null,
        filterSmarForm:'incomplete'
      }
    },
    created() {
      this.loading('show')
      this.item= null
      this.binds= null
      this.layout= null
      setTimeout(() => {
        this.item= this.record
        this.binds= this.recordView.binds
        this.layout= this.recordView.layout
        this.ACLPermits= this.recordView.ACLPermits
        this.loading('close')
      }, 1000);
    },
    methods: {
      //Vuex
      ...Vuex.mapActions(['updateRecord']),
      //Tools
      loading(action){
        if (action == 'show') {
          document.getElementById("loading").classList.add('show')
          document.getElementById("body").classList.add("noScroll")
        }else{
          document.getElementById("loading").classList.remove('show')
          document.getElementById("body").classList.remove("noScroll")
        }
      },
      //Local Functions
      actionRecord( action ){
        this.loading('show')
        switch (action) {
          case 'back':
            this.loading('close')
          break;
          case 'save':
            this.updateRecord(this.binds)
            this.loading('close')
            Swal.fire({
              icon: 'success',
              title: 'Saved Successfully',
              text: '',
            })
          break;
          case 'saveClose':
            this.updateRecord(this.binds)
          break;
        }
      },
      async sendReview( data ){
        let pushData = ''
        try {
          pushData = await API.graphql({
            query: smd_notify,
            variables: {
              itemPK: this.publicOrganization,
              itemSK: this.$router.currentRoute.value.query.data,
            },
          });
        pushData = pushData.data.smd_notify;
        console.log(pushData);
        Swal.fire({
          icon: 'success',
          title: 'Form Sent. Thank you.',
          text: '',
        })
        } catch (error) {
          console.log(error);
        }
      },
    },
    computed:{
    ...Vuex.mapState(["organizationID", "entity", "permissions", "entities", "allowPK", "record", "recordView","publicOrganization"]),
    }
  }
</script>
<style lang="less">
  .body-wrap{
    margin: 20px 0 0 0;
    height:80vh;
    overflow-y: auto;
  }
</style>  