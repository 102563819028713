<template>
  <div class="smart-wrap" v-if="layout != null">
    <div :class="setClass(component)+' preview'" :style="setStyle(component)"  v-for="(component, index) in layout" :key="index">
      <!-- Inputs -->
      <div  v-if="component.metaType == 'metaInput' && component.print" :style="styleWrap(index)">
        <!--p style="color: #949494; margin:0; font-size: 12px; "><strong>Read Only: </strong>{{ component.readOnly }}</p--> 
        <!-- LABEL/HELP -->
        <label for="" style="z-index:999;">{{ component.label }} 
        </label>

        <!-- Text -->
          <p v-if="component.inputType == 'GRAPHQL' || component.inputType == 'GRAPHQLPLUS'" style="color: #5A5A5A;font-weight: bold;font-size: 18px;" >{{models[component.attributeName].value.value}}</p>
          <p v-else style="color: #5A5A5A;font-weight: bold;font-size: 18px;" >
            <span v-if="component.format == 'currency'" >{{ (models[component.attributeName].value).toLocaleString('en-US', { style: 'currency', currency: 'USD'}) }}</span>
            <span v-else >{{models[component.attributeName].value}}</span>
          </p>

      </div> 

      <!-- Layout -->
      <div v-if="component.metaType == 'metaLayout' && component.print">
        <h5>{{component.label}}</h5>
        <div class="element-wrap" v-if="component.parentChildren">
          <table class="table" id="myTable" style="overflow: auto;"> 
            <thead>
              <th> No. </th>
              <th v-for="(header, yindex) in component.headers" :key="yindex" > {{header.label}} {{header.attributeType}} </th> 
            </thead>
            <tbody  >
              <tr class="input-wrap" v-for="(xitem, xindex) in models[component.attributeName].externalValue" :key="xindex"> 
                <td>{{xindex}}</td>
                <td v-for="(header, yindex) in component.headers" :key="yindex">
                  {{valueInternalTable(xitem.attributes[header.attributeName])}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span style="color: #ccc; font-size: 10px; display:none;" v-if="component.isRepeatable == false && component.parentChildren == undefined ">{{ models[component.attributeName].value = setValueLayout(component)}}</span>
        <paper-preview :data="data" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" v-if="component.isRepeatable == false && component.parentChildren == undefined "/>
      </div>

      <!-- Element   -->
      <div v-if="component.metaType == 'metaElement' && component.print">
        <div class="row" v-if="component.elementType == 'Collapse'">
          <h6 style="width:100%;" data-toggle="collapse" :href="'#collapse-'+component.no" role="button" aria-expanded="false" aria-controls="collapseExample">
            {{component.text}}
          </h6>
          <div style="width:100%;" class="collapse" :id="'collapse-'+component.no">
            <paper-preview :data="data" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="models" />
          </div>
        </div>
        <div class="row" v-if="component.elementType == 'Img'">
          <img style="width: 100%;" :src="component.url" alt="" srcset="">
        </div>
        <div class="row" v-if="component.elementType == 'Text'">
          <span>{{component.text}}</span>
        </div>
        <div class="row" v-if="component.elementType == 'Title'">
          <h6>{{component.text}}</h6>
        </div>
        <div class="row" v-if="component.elementType == 'Div'">
          <paper-preview :data="data" :paperPermissions="paperPermissions" :layout="component.smartComponents" :models="modelsInternalTable" />
        </div>
        <button v-if=" component.elementType == 'Button' && component.actionJSON" class="btn-gray btn-g "  v-on:click="buttonBackEnd(component.actionJSON)">
          <svg>
            <rect x="0" y="0" fill="none" width="100%" height="100%" />
          </svg>
          {{component.text}}
        </button>
        <span v-if="component.elementType == 'Button' && !component.actionJSON" style="border: 1px dashed rgb(0, 0, 0);width: 100%;display: block;padding: 10px;text-align: center;">
          No formula found <strong>"{{component.attributeName}}"</strong> 
        </span>
      </div>

      <!-- Module  -->
      <div class="module-wrap" v-if="component.metaType == 'metaModule' && component.print">
        <h6 style="width:100%; cursor:pointer;" v-on:click="setPanel(component)">
          {{component.text}}
        </h6> 
      </div>

      <!-- Table -->
      <div class="element-wrap inhert" v-if="component.isRepeatable == true && component.print">
        <table class="table" id="myTable" style="overflow: hidden; background: none;"> 
          <thead>
            <th> No </th>
            <th v-for="(column, indexcolumns) in setHeaders(index) " :key="indexcolumns"  >
              {{column.label + ' - ' +column.attributeType}}
            </th>
          </thead>
          <tbody v-if="component.metaType != 'metaInput'" >
            <tr class="input-wrap readOnly" v-for="(item, indexItem) in models[component.attributeName].value" :key="indexItem">
              <td>{{indexItem+1}}</td>
              <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                <div v-if="component.read == true">
                  <span v-if="column.attributeType.toLowerCase() == 'currency'" >
                    {{ ( JSON.parse(models[component.attributeName].value[indexItem][column.attributeName])).toLocaleString( 'en-US', { style: 'currency', currency: 'USD'} )     }}
                  </span>
                  <span v-else>
                    {{ models[component.attributeName].value[indexItem][column.attributeName] }}
                  </span>
                </div>

              </td>
            </tr>
            <tr  v-if="component.inputType == 'GRAPHQLPLUS'"> 
              <td>Total</td>
              <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                <span v-if="column.attributeType == 'Currency'" >   
                  {{f_totals(index,component)}}
                </span>
              </td> 
              <td></td>
            </tr>
          </tbody>

          <tbody v-if="component.metaType == 'metaInput'" >
            <tr class="input-wrap readOnly"  v-for="(item, indexItem) in models[component.attributeName].value.data" :key="indexItem">
              <td>{{indexItem+1}}</td>
              <td  v-for="(column, indextd) in setHeaders(index)" :key="indextd">
                <div  v-if="component.readOnly == true">
                  {{models[component.attributeName].value[indexItem][column.attributeName]}}
                </div>
                <div v-else>
                  <div class="row" v-if=" column.attributeType == 'GRAPHQL' || column.attributeType == 'GRAPHQLPLUS'" >
                    <span :style="'width:100%; float: left; overflow:hidden;'" v-for="(zitem, zindex) in component.metaJSON.attributes" :key="zindex"> 
                      <label for="">{{zitem.label}}</label> <br>
                      <span v-if="zitem.attributeType == 'Currency'" > {{( JSON.parse(models[component.attributeName].value.data[indexItem][zitem.attributeName])).toLocaleString( 'en-US', { style: 'currency', currency: 'USD'} )  }} </span>
                      <span v-else> {{models[component.attributeName].value.data[indexItem][zitem.attributeName]}} </span>
                      <br><br>
                    </span>
                  </div>
                  <input v-if=" column.attributeType != 'GRAPHQL' && column.attributeType != 'GRAPHQLPLUS'"  class=" " type="text" style="height: 40px;" v-model="models[component.attributeName].value[indexItem][column.attributeName]">
                </div>
              </td>
            </tr>
            <tr v-if="component.inputType == 'GRAPHQLPLUS'"> 
              <td>Total</td>
              <td>  
                <span v-for="(total, indextd) in f_totals(index,component)" :key="indextd">
                  <strong>{{indextd}}:</strong> {{ ( total ).toLocaleString('en-US', { style: 'currency', currency: 'USD'}) }} <br>
                </span>
              </td> 
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <span v-if="component.print == undefined" style="border: 1px dashed rgb(0, 0, 0);width: 100%;display: block;padding: 10px;text-align: center; overflow:auto;">
          Print is not defined <strong>"{{component.attributeName}}"</strong> 
      </span>
    </div>
  </div>
</template>
<script>
//AWS
import {smd_updateSmartRecord, smd_setItemState, smd_executeBackendFunctions } from '../../graphql/mutations'
import {smd_getSMLItem } from '../../graphql/queries'
//Components
import PaperDisplayer from './PaperDisplayer.vue';
import PaperPreview from './PaperPreview.vue'
//Tools
import { API, Storage } from "aws-amplify";
import Vuex from "vuex"; // Use a Variables Globals
import trumbowyg  from 'trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css';
import { v4 as uuidv4 } from 'uuid';  

export default {
  name: "paper-preview",
  components:{
    PaperDisplayer,
    PaperPreview,
  },
  data() {
    return {
      fileView: [],
      securityFile: 'public',
      smName: "dfault",
      w_fileColumns: [{attributeName: "name", label:"Name"}], //Number of combos
      htmlTag : {tag:'none', help:'none'},
      totals : [] ,
      helpC: true,
      anchor: 0,
      searchSelect: '',
      test: '',
      //Internal Table
      itemView: null,
      modelsInternalTable:[] ,
      layoutInternalTable:null,
    }
  },
  props: {
    layout:{
      type: Array,
    },
    models:{
    },
    parent:{
      type: String,
      default: 'general'
    },
    edit:{
      type: Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {
        PK: null,
        SK: null,
      }
    },
    paperPermissions:{
      type: Object,
      default:{
        usersCanEdit:[],
        usersCanRead:[],
        groupsCanEdit:["Admin","Superadmin","Editor"],
        groupsCanRead:["Admin","Superadmin","Editor"],  
      }
    },
  },
  created() {
    this.phoneList = [ ]
  },
  methods: {
    //Tools
    goodFormat(data, format){
      let vdata = ''
      try {        
        switch (format) {
          case 'string':
            vdata = JSON.stringify(data, null,4)
          break;
          case 'json':
            vdata =JSON.parse(JSON.stringify(data))
          break;
        }
        return vdata
        
      } catch (error) {
        console.log('-->Good Format Error: '+format+'\n', data);
      }
    },
    //Global Methods
    ...Vuex.mapMutations(["SetOrganizationID","SetPageForm","SetPageTable","SetPageName","SetPageQuery", "SetPageMeta", "SetPageModule", "SetPageEntity"]),
    //Component Funcionality
    async buttonBackEnd(formula) {
      let pushContent = ''
      pushContent = await API.graphql({
        query: smd_executeBackendFunctions, //Custom Button
        variables: { 
          PK: this.data.PK,
          SK: this.data.SK,
          formula: JSON.stringify(formula)
        },
      });
      console.log(pushContent);
    },
    //Layout Funcionality
    
    setElementComputed(component){
      //console.clear();
      let i = i++
      console.log('setElementComputed------------------- \n '+component.attributeName+'\n ');
      let value = ''
      let formula = ''
      if (component.formula.includes('+') || component.formula.includes('-') || component.formula.includes('x') || component.formula.includes('/')) {
        try {
          value = component.formula
          for (const key in this.models) {
            if (component.formula.includes(key)) {  
              console.log(key);
              if (this.models[key].value == '') {
                value = value.replace(key, '0' )   
              }else{
                value = value.replace(key,this.models[key].value )   
              }
              console.log(key +': '+this.models[key].value);
            }
          }
          console.log(value);
          value = eval(value)
          this.models[component.attributeName].value = value
        } catch (error) {
          console.log(error);
        }
      }else{
        console.log(component.formula);
        value = this.models
        formula = component.formula.split(':')
        try {        
          for (let index = 0; index < formula.length; index++) {
            value = value[formula[index]]
          }
          this.models[component.attributeName].value = value
        } catch (error) {
          console.log(error);
        }
      }
    },
    setClass(component){
      let wrap = 'undefined'
      try {
        if (component.metaType) {
          wrap = component.metaType.replace('meta','').toLowerCase()+'-wrap'
          if (component.readOnly == true ||component.edit == false  ) {
            wrap = wrap + ' readOnly'
          }
          if (component.format != undefined && component.format != '' ) {
            wrap = wrap +" "+component.format
          }
          if (component.read == false) {
            wrap = 'displayNone'
          }
          if (component.print == false) {
            wrap = 'displayNone'
          }
        }
      } catch (error) {
        
        console.log(component ,error);
      }
      return wrap
    },
    setStyle(component){
      let style = ''
      try {
        style = component.style
      } catch (error) {
        console.log(JSON.stringify(component, null, 4));
      }
      return style
    },
    setEditor(attributeName,index){
      try {
        setTimeout(() => {
          $(`#editor${index}`).trumbowyg();
          document.getElementById(`editor${index}`).innerHTML = unescape(this.models[attributeName].value)
        }, 100);
      } catch (error) {
        
      }
    },
    setDataEditor(attributeName,index){
      try {
        setTimeout(() => {
          this.models[attributeName].value = escape(document.getElementById(`editor${index}`).innerHTML)
        }, 100);
      } catch (error) {
        
      }
    },
    setValueLayout(component){
      let w_temp = {}
      try {
        for (let index = 0; index < component.smartComponents.length; index++) {
          const element = component.smartComponents[index];
          w_temp[element.attributeName] = this.models[element.attributeName].value
        }
      } catch (error) {
        console.log(element.attributeName,this.models[element.attributeName]);
        console.log(error);
      }
      return w_temp
    },
    setHeaders(index){
      if (this.layout[index].headers.length <= 0 ) {
        return [{label: this.layout[index].attributeName+'', attributeName: this.layout[index].attributeName, attributeType: 'Text' }]
      }
      else{
        return this.layout[index].headers
      }
    },
    f_searchSelect(vindex){
      let list = document.getElementById("dropdown"+vindex);
      console.log(list.children);
      for (let i = 1; i < list.children.length; i++) {
        let txtValue = list.children[i].textContent || list.children[i].innerText;
        if (txtValue.toLowerCase().indexOf(this.searchSelect.toLowerCase()) > -1) { 
          document.getElementById("dropdown"+vindex).children[i].style.display = "";
        } else {
          document.getElementById("dropdown"+vindex).children[i].style.display = "none";
        }
      }
    },
    setValueSelect(index, value, component){
      let w = value
      console.log(index);
      console.log(this.layout[index]);
      if (this.models[component.attributeName].value == value) {
        console.log('if');
        this.models[component.attributeName].value = ''
      }else{
        console.log('else');
        if (this.layout[index].metaType == 'metaInput') {
          if (this.layout[index].isRepeatable == true) {
            console.log('Es bingo');
            this.layout[index].itemTable = w
            this.anchor++
          }else{
            this.models[component.attributeName].value = value
          }
        }else{
          this.models[component.attributeName].value = value
        }
      }
    },
    unSetValueSelect(component, value){
      if (this.models[component.attributeName].value == value) {
        return 'cursor:pointer; background: #0057ff; color:#fff;'
      }else{
        return 'cursor:pointer;'
      }
    },
    help(component) {
      console.log(component);
      let help = document.getElementById('help'+component.id).classList;
      document.getElementById('help'+component.id).innerHTML = unescape(component.help)
      if (help[1] == "show") {
        document.getElementById('help'+component.id).classList.remove("show")
      }else[
        document.getElementById('help'+component.id).classList.add("show")
      ]
    },      
    alert(tag, index) {
      let help = document.getElementById(tag).classList;
      if (help[1] == "show") {
        document.getElementById(tag).classList.remove("show")
      }else[
        document.getElementById(tag).classList.add("show")
      ]
    },    
    f_itemValueCombo(item, column, parent) {
        let td = ""
        if (parent == true) {
        td = item[column.parent][column.attributeName]
          try {
            if (td) {
              td = JSON.parse(td)
            }
          } catch (error) {
          }

        }else{
          if (item[column]) {
            td = item[column]
          }else{
            td = "..."
          }
        }
        return  td
    },
    f_totals(index, component){
      let totals = { }
      try {
        if (this.layout[index].metaType == 'metaInput') {
          switch (this.layout[index].inputType) {
            case 'GRAPHQLPLUS':
              //console.log(JSON.stringify(this.layout[index].metaJSON.attributes, null, 4)); 
              this.layout[index].metaJSON.attributes.forEach(attribute => {
                if (attribute.attributeType == 'Currency') {
                  for (let zindex = 0; zindex < this.models[component.attributeName].value.data.length; zindex++) {
                    if (!totals[attribute.attributeName]) {
                      //console.log('Primera Pasada');
                      totals[attribute.attributeName] = 0
                    }
                    totals[attribute.attributeName] = totals[attribute.attributeName] + parseInt(this.models[component.attributeName].value.data[zindex][attribute.attributeName])
                    this.models[component.attributeName].value.totals = totals
                    /*if (attribute.target) {
                      this.models[attribute.target].value = totals[attribute.attributeName]
                    }*/
                  }
                }
              });
            break;
          }
        }
        //console.log(this.goodFormat(this.models[component.attributeName].value, 'json'))
        return totals
      } catch (error) {
        //console.log(error);
      }
    },
    styleWrap(index){
      if (this.layout[index].isRepeatable == true && this.layout[index].metaType == 'metaInput') {
        return 'padding: 0px 40px 0px 0px;'
      }
    },
    f_styleInpu(index){
      if (this.layout[index].readOnly == true) {
        return 'border:none; cursor:default;'
      }
    },
    f_repeatableAction(vindex, action , component, tdIndex ) {
      console.log('\n ');
      console.log(action);
      console.log(vindex);
      switch (action) {
        case 'push':
          console.log(JSON.stringify(this.layout[vindex].metaType, null, 4));
          console.log(component.attributeName);
          console.log(JSON.stringify(this.models[component.attributeName], null, 4));
          if (this.models[component.attributeName].value == undefined ) {
            this.models[component.attributeName].value = []
          }
          if (this.layout[vindex].metaType == 'metaLayout') {            
            if (!this.models[component.attributeName].value.length ) {
              this.models[component.attributeName].value = []
            }
            console.log(this.models[component.attributeName].value);
            this.models[component.attributeName].value.push({})
            this.layout[vindex].headers.forEach(element => {
              this.models[component.attributeName].value[this.models[component.attributeName].value.length-1][element.attributeName] = ''
            });
          }
          if (this.layout[vindex].metaType == 'metaInput') {
            if (this.layout[vindex].inputType == 'GRAPHQLPLUS') {
              if (this.layout[vindex].itemTable) {
                console.log('before'+JSON.stringify(this.models[component.attributeName].value, null, 4));
                if (!this.models[component.attributeName].value.data) {
                  console.log('bad');
                  this.models[component.attributeName].value = {data:[],totals:[]}
                }
                this.models[component.attributeName].value.data.push(this.layout[vindex].itemTable)
                this.layout[vindex].itemTable = ''
                console.log('after'+JSON.stringify(this.models[component.attributeName].value, null, 4));
              }
              else{
                console.log(this.layout[vindex].itemTable);
                console.log(' No hay Valor en select');
              }
            }
          }
        break;
        case 'push-internalTable':
          console.log(JSON.stringify(this.layout[vindex].metaType, null, 4));
          console.log(component.attributeName);
          if (this.models[component.attributeName].value == undefined || !this.models[component.attributeName].value.length) {
            this.models[component.attributeName].value = []
          } 
          let tempModel = { }
          tempModel.SK = undefined
          tempModel.PK = undefined
          tempModel.read =true
          tempModel.edit =true
          tempModel.shortEntity = this.layout[vindex].shortEntity
          tempModel.attributes = {}
          tempModel.form= {
            PK: this.layout[vindex].form.PK,
            SK: this.layout[vindex].form.SK
          },
          tempModel.entity={
            PK: this.layout[vindex].entity.PK,
            SK: this.layout[vindex].entity.SK
          } 
          for (let index = 0; index < component.smartComponents.length; index++) {
            const element = component.smartComponents[index]
            console.log(component.smartComponents[index].attributeName);
            tempModel.attributes[element.attributeName] ={
              value: '',
              label: element.label,
              inputType: element['metaInput.inputType'],
              attributeType: 'S',
            }
          }
          console.log(tempModel);
          console.log(JSON.stringify(tempModel, null, 2));
          console.log(JSON.stringify(this.models[component.attributeName], null, 2));
          this.models[component.attributeName].push(tempModel)

        break;
        case 'delete':
          if (this.layout[vindex].metaType == 'metaLayout') {            
            this.models[component.attributeName].value.splice(tdIndex, 1)
          }
          if (this.layout[vindex].metaType == 'metaInput') {
            if (this.layout[vindex].inputType == 'GRAPHQLPLUS') {
              this.models[component.attributeName].value.data.splice(tdIndex, 1)
            }
            else{
              this.models[component.attributeName].value.splice(tdIndex, 1)
            }
          }
        break;
      }
      this.anchor++
    },
    f_checks(status, index) {
      console.log(status);
      console.log(index);
      console.log('----------------------------------------------------------------------------');
    },
    f_radioSingle( name){
      //console.log(this.models[name].value);
    },
    stringify(value) {
      console.log(JSON.stringify(value, null, 4));
      return JSON.stringify(value, null, 4)
    },
    f_htmlTag(value){
      console.log(value);
      switch (value) {
        case 'Title':
          this.htmlTag.tag = '<h4>'
        break;
        case 'Text':
          this.htmlTag.tag = '<p>'
        break;
        case 'Link':
          this.htmlTag.tag = '<a>'
        break;
        case 'Div':
          this.htmlTag.tag = '<div>'
        break;
        case 'Section':
          this.htmlTag.tag = '<section>'
        break;
        case 'Img':
          this.htmlTag.tag = '<img>'
        break;
        case 'Form':
          this.htmlTag.tag = '<form>'
        break;
        case 'Header':
          this.htmlTag.tag = '<header>'
        break;
        case 'Footer':
          this.htmlTag.tag = '<footer>'
        break;
        case 'Menu':
          this.htmlTag.tag = '<nav>'
        break;
        case 'Table':
          this.htmlTag.tag = '<table>'
        break;
        case 'Video ':
          this.htmlTag.tag = '<video>'
        break;
        case 'Iframe ':
          this.htmlTag.tag = '<iframe>'
        break;
        case 'Button ':
          this.htmlTag.tag = '<button>'
        break;
      }
    },
    f_validationName(value, target){
      if (typeof value[target] != 'object' ) {
        return JSON.parse(value[target])
      }else{
        return value[target]
      }
    },
    //file Down and Up  
    async fileUp(e){
      let pre_key = uuidv4()
      let name = e.target.value.replace('C:\\fakepath\\', '')
      let extension = name.split('.')
      let key = pre_key+"."+extension[1]
      let file = e.target.files[0]
      let index = e.target.id
      console.log(this.layout[index]);
      if (file == "undefined" || file == undefined || file == null) {
        console.log('Error, no se ha subido un archivo');
      }else{      
        try {
          await Storage.put( this.organizationID+"/"+this.parent+"/"+key, file,{ level: this.securityFile } ) 
          console.log('Subido');
          console.log(this.organizationID+"/"+this.parent+"/"+key);
          await this.f_fileConstructor(name, key, index, e.target )
        } catch (error) {
          console.log('Error uploading file:\n ', error);
        }
      }
    },
    async f_fileConstructor(name, key, index, target){
      try {
        let component = this.layout[index]
        console.log(component);
        //let url = await Storage.get(this.organizationID+"/"+this.parent+"/"+key, { level: this.securityFile });
        console.log('URL of GET');
        if (component.isRepeatable == true) {
          let att = target.name.split(',')
          this.models[component.attributeName].value[att[2]][att[0]]= { name: name, url: url, key: key, security:this.securityFile }
          this.anchor++
          this.test = this.models[component.attributeName].value
        }else{
          console.log(this.models[component.attributeName]);
          if (typeof this.models[component.attributeName].value != 'object') {
            this.models[component.attributeName].value= []
          }
          this.models[component.attributeName].value.push({ name: name, url: url, key: key, security: this.securityFile })
        }
      } catch (error) {
        console.log(error);
      }

    },
    async fileDelete( file, index, indexItem ){
      let component = this.layout[index] 
      if (file == "") {

      }else{        
        await Storage.remove(this.organizationID+"/"+this.parent+"/"+key, { level: file.security });
        console.log('eliminado');
        try {          
          for (let vindex = 0; vindex < this.models[component.attributeName].value; vindex++) {
            if (file == this.sections[index].itemModel[vindex].key) {
              this.sections[index].itemModel.splice([vindex],1)
            }
          }
        } catch (error) {
          this.models[this.layout[index].attributeName].value
        }
      }
    },
    //Internal Table
    async setStatus(item, component, action, index) {
      console.log(item.PK, item.SK);
      let status = ''
      let push =''

      if (action == 'active') {
        status ='1'
      }
      if (action == 'desactive') {
        status= '0'
      }
      if (action == 'archive') {
        status = '2'
      }
      console.log(action+'-'+status);
      try {        
        push = await API.graphql({
          query: smd_setItemState,
          variables: { PK: item.PK, SK: item.SK, state: status},
        });
        console.log(push);
        this.models[component.attributeName].externalValue.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    },
    valueInternalTable(model) {
      let value = ''
      if (model.value) {
        value = model.value
      }
      return value
    },
    async editInternalTable( component, SK, comand ){
      //console.clear()
      console.log("--> Component :",this.goodFormat(component, 'json'));
      console.log(SK);
      console.log("--> itemView :", this.goodFormat(this.itemView, 'json'));
      let models = this.modelsInternalTable 

      for (const key in models) {
        try {
          if (models[key].type == 'L' &&  models[key].value != '') {
            models[key].value = JSON.stringify(models[key].value)
          }
          if (models[key].type == 'HTML' && models[key].value != '') {
            models[key].value = escape(models[key].value)
          }
        } catch (error) {
          console.error(error);
        }
      }

      console.log("--> data :", this.goodFormat(this.data, 'json'));
      let push = ''
      try {
        push = await API.graphql({
          query: smd_updateSmartRecord, //smd_updateSmartRecord
          variables: { 
            input:{
              PK: this.data.PK ,
              SK: SK ,
              attributesStr: JSON.stringify(models, null, 4),
              form: {
                PK:this.itemView.form.PK,
                SK:this.itemView.form.SK,
              },
              entity: {
                PK:this.itemView.entity.PK,
                SK:this.itemView.entity.SK,
              },
              shortEntity: this.itemView.shortEntity,
              parent: this.data,
              usersCanEdit: this.paperPermissions.usersCanEdit,
              usersCanRead: this.paperPermissions.usersCanRead,
              groupsCanEdit:this.paperPermissions.groupsCanEdit,
              groupsCanRead:this.paperPermissions.groupsCanRead,
            }
          },
        });
        push = push.data.smd_updateSmartRecord
        console.log(component.attributeName);
        console.log(push);
        if (SK == undefined) {
          this.models[component.attributeName].externalValue.push({
            attributes:this.modelsInternalTable,
            PK: push.PK,
            SK: push.SK,
            edit: true,
            read: true,
            entity: component.entity,
            form: component.form,
          })
        }
        console.log('----------------------------------------');
        this.itemView.SK = push.PK
        this.itemView.PK = push.SK
        if (comand == 'close') {
          console.log(document.getElementById('modal-'+component.id));
          this.closeInternalModal(component)
        }
      } catch (error) {
        console.log(error);
      }
      for (const key in this.modelsInternalTable) {
        try {
          //console.log(key+': ',this.models[key]);
          if (this.modelsInternalTable[key].type == 'L' && this.modelsInternalTable[key].value != '') {
            this.modelsInternalTable[key].value = JSON.parse(this.modelsInternalTable[key].value)
          }
        } catch (error) {
          console.error('--> Error \n '+ key+': ',this.goodFormat(this.modelsInternalTable[key], 'json') );
        }
      }
    },
    closeInternalModal(component){
      document.getElementById('modal-'+component.id).classList.remove('show')
    },
    async getItemInternalTable(component, parent, item, index){
      console.log(item);
      console.log(JSON.stringify(this.models[component.attributeName], null ,4));
      let pullQuery = ''
      try {
        if (item == null) {
          pullQuery = await API.graphql({
            query: smd_getSMLItem,
            variables: {
              SMLPK: component.form.PK,
              SMLSK: component.form.SK,
              parent: {
                PK: parent.PK,
                SK: parent.SK
              },
              entity:{
                PK: component.entity.PK,
                SK: component.entity.SK,
              }
            },
          });
        }else{
          console.log(item.SK);
          pullQuery = await API.graphql({
            query: smd_getSMLItem,
            variables: {
              itemPK:item.PK,
              itemSK:item.SK,
              SMLPK: component.form.PK,
              SMLSK: component.form.SK,
              parent: {
                PK: parent.PK,
                SK: parent.SK
              },
              entity:{
                PK: component.entity.PK,
                SK: component.entity.SK,
              }
            },
          });
  
        }
        console.log('> Data Query ', pullQuery.data.smd_getSMLItem);
        pullQuery = pullQuery.data.smd_getSMLItem
        this.itemView = JSON.parse(pullQuery.item)
        if (item == null ) {
          this.itemView.SK = undefined
        }
        console.log('--> Item:',this.itemView);
        this.layoutInternalTable = JSON.parse(pullQuery.data)
        console.log('--> Layout:',this.layoutInternalTable);
        this.modelsInternalTable =this.itemView.attributes
        console.log('--> Models:',this.modelsInternalTable);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...Vuex.mapState(["organizationID", "pageForm", "pageTable", "pageName", "pageQuery", "entity", "pageMeta", "pageModule", "permissions"]),
    elementComputed:{
      get: function () {
        return 'gggg'
      },
      // setter
      set: function (newValue) {
        console.log(newValue);
      }
    }
  },
  watch:{
    models:function(value) {
      //console.log(value);
    },
  }
}
</script>